import React, { useEffect, useState } from 'react'; 
import { db } from '../firebase/firebaseConfig';
import { getDocs, collection, where, query } from 'firebase/firestore';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { Card, Container, Segment, Table, Input } from 'semantic-ui-react'
import { Titulo } from '../elementos/General';
import moment from 'moment';


const ServicioTecnico = () => {
    // const user = auth.currentUser;
    const { users } = useContext(UserContext);

    const [asignar, setAsignar] = useState([]);
    const [buscador, setBuscador] = useState('')
    const [usuarios, setUsuarios] = useState([])

    const getIngresostcab = async () => {
        if (!users) return;
        const traerCabecera = collection(db, 'ingresostcab');
        const dato = query(traerCabecera, where('emp_id', '==', users.emp_id));
        const data = await getDocs(dato)
        setAsignar(data.docs.map((doc, index) => ({ ...doc.data(), id: doc.id })))
    }

    const leerUsuarios = async () => {
        if (!users) return;
        const traer = collection(db, 'usuarios');
        const doc = query(traer, where('emp_id', '==', users.emp_id));
        const documento = await getDocs(doc)
        setUsuarios(documento.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    // const asignarOrdmp = mantoprev.sort((a, b) => a.folio - b.folio);
    const ingresado = asignar.filter(p => p.estado === 'INGRESADO').length;
    // const asignarOrdp = presu.sort((a, b) => a.folio - b.folio);
    const asignado = asignar.filter(e => e.estado === 'ASIGNADO').length;
    // const asignarOrde = evaluacion.sort((a, b) => a.folio - b.folio);
    const cerradas = asignar.filter(e => e.estado === 'CERRADO').length;
    //Filtrar distintos de anulado 
    const todas = asignar.filter(e => e.estado !== 'ANULADO');

    //Funcion calculo promedio de cierre
    function calculoPromedioCierre(ordenes) {
        // Filtrar órdenes que tienen fecha de cierre
        const ordenesCerradadas = ordenes.filter(orden => orden.fecha_out);

        // Si no hay órdenes asignadas, devolver un mensaje o 0
        if (todas.length === 0) {
            return "No hay órdenes cerradas";
        }

        // Calcular la diferencia en horas para las órdenes asignadas
        const diferencias = ordenesCerradadas.map(orden => {
            const ingreso = orden.date.toDate();
            const cerrada = orden.fecha_out.toDate();
            return (cerrada - ingreso) / (1000 * 60 * 60 * 24); // Diferencia en dias
        });

        // Calcular el promedio de las diferencias
        const sumaDiferencias = diferencias.reduce((acum, tiempo) => acum + tiempo, 0);
        const promedioHoras = sumaDiferencias / diferencias.length;

        // Limitar a 4 decimales
        return promedioHoras.toFixed(3);
    }

    // Cambiar fecha timestap format date
    const formatearFecha = (fecha) => {
        if (fecha === '') {
            return '';
        } else {
            const dateObj = fecha.toDate();
            const formatear = moment(dateObj).format('DD/MM/YYYY HH:mm');
            /*  const fechaHoyF = moment(fechaHoy).format('DD/MM/YYYY HH:mm');
             console.log(fechaHoyF + " es menor que ? " + formatear, fechaHoy < dateObj) */
            return formatear;
        }

    }

    const todasSort = todas.sort((a, b) => a.folio - b.folio)

    // Filtrar los datos basado en el valor de búsqueda
    const filteredData = buscador ? todasSort.filter(item =>
        Object.keys(item).some(key => {
            const value = ['date', 'fecha_out'].includes(key) ? formatearFecha(item[key]) : String(item[key]).toLowerCase();
            return value.includes(buscador.toLocaleLowerCase());
        })
    ) : [];

 
    useEffect(() => {
        getIngresostcab();
        leerUsuarios();
        /* calculoPromedioCierre(todas); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    return (
        <Container>
            <Segment raised color='teal'>
                <Titulo style={{ marginTop: '30px',zIndex:'1' }}>DashBoard</Titulo>             
                <div style={{ margin: '30px',zIndex:'1'}}>
                    <Card.Group itemsPerRow={4} centered >
                        {/* <Card color='red' style={{ padding: '20px', fontSize: '50px' }}>
                    <h4>Total por Confirmar</h4>
                    <p>{porconfimar}</p>
                </Card> */}
                        <Card color='orange' style={{ padding: '15px', fontSize: '30px',width:'120px' }}>
                            <h4 style={{ fontSize: '12px' }}>Ingresadas</h4>
                            <p>{ingresado}</p>
                        </Card>
                        <Card color='yellow' style={{ padding: '15px', fontSize: '30px',width:'120px'}}>
                            <h4 style={{ fontSize: '12px' }}>Asignadas</h4>
                            <p>{asignado}</p>
                        </Card>
                        <Card color='olive' style={{ padding: '15px', fontSize: '30px',width:'120px'}}>
                            <h4 style={{ fontSize: '12px' }}>Cerradas </h4>
                            <p>{cerradas}</p>
                        </Card>
                        <Card color='teal' style={{ padding: '15px', fontSize: '30px',width:'120px' }}>
                            <h4 style={{ fontSize: '11px' }}>Prom.Cierre Días.</h4>
                            <p>{calculoPromedioCierre(todas)}</p>
                        </Card>

                    </Card.Group>
                </div>
            </Segment>
            <Segment  raised color='teal'  style={{ maxWidth: "100%", padding: "10px" }}>
                <Segment>        
        
                    <Input style={{ width: '100%', outlineColor: '#F0A70A', fontSize: '20px', color: '#328AC4' }}
                        type='text'
                        placeholder='Buscar Ordenes'
                        value={buscador}
                        onChange={e => setBuscador(e.target.value)}
                        icon='search'
                    />
                    {/*  <FaIcons.FaFileExcel  onClick={ExportarXls}  style={{ fontSize: '20px', color: '#328AC4', marginLeft: '20px', cursor:'pointer' }} title='Exportar Equipos a Excel' /> */}
                </Segment>
                <Container style={{ overflowX: "auto", maxWidth: "100%",overflowY: "auto",maxHeight: "500px",padding:'10px' }}>
                <Table celled singleLine striped selectable style={{ fontSize: '11px' }}>
                    <Table.Header style={{ position: "sticky", top: "0", background: "#f8f8f8", zIndex: "1",borderTop: "2px solid #ddd" }}>
                        <Table.Row>
                            <Table.HeaderCell>N°</Table.HeaderCell>
                            <Table.HeaderCell>Cliente</Table.HeaderCell>
                            <Table.HeaderCell>Fecha Ingreso</Table.HeaderCell>
                            <Table.HeaderCell>Ingresado Por</Table.HeaderCell>
                            <Table.HeaderCell>Equipo</Table.HeaderCell>
                            <Table.HeaderCell>Modelo</Table.HeaderCell>
                            <Table.HeaderCell>N°Serie</Table.HeaderCell>
                            <Table.HeaderCell>Servicio</Table.HeaderCell>
                            <Table.HeaderCell>Estado</Table.HeaderCell>
                            <Table.HeaderCell>Fecha Cierre</Table.HeaderCell>
                            <Table.HeaderCell>Tecnico Asignado</Table.HeaderCell>
                            <Table.HeaderCell>Dias</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {filteredData.length > 0 ? (
                            filteredData.map(item => (
                                <Table.Row key={item.id}>
                                    <Table.Cell>{item.folio}</Table.Cell>
                                    <Table.Cell
                                        /* style={{
                                            maxWidth: '150px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer'
                                        }}
                                        title={item.entidad} */
                                    >{item.entidad}</Table.Cell>
                                    <Table.Cell
                                    /* style={{
                                        maxWidth: '90px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }} */
                                    >{formatearFecha(item.date)}</Table.Cell>
                                    <Table.Cell>{item.useradd}</Table.Cell>
                                    <Table.Cell
                                      /*   style={{
                                            maxWidth: '150px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer'
                                        }}
                                        title={item.tipo} */
                                    >{item.tipo}</Table.Cell>
                                    <Table.Cell
                                    /* style={{
                                        maxWidth: '100px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }} */
                                    title={item.modelo}
                                    >{item.modelo}</Table.Cell>
                                    <Table.Cell>{item.serie}</Table.Cell>
                                    <Table.Cell
                                     /*    style={{
                                            maxWidth: '150px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            cursor: 'pointer'
                                        }}
                                        title={item.servicio} */
                                    >{item.servicio}</Table.Cell>
                                    <Table.Cell>{item.estado}</Table.Cell>
                                    <Table.Cell
                                   /*  style={{
                                        maxWidth: '90px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: 'pointer'
                                    }} */
                                    >{item.fecha_out ? formatearFecha(item.fecha_out) : 'Pendiente'}</Table.Cell>
                                    <Table.Cell>{usuarios.map((user, index) => (
                                        user.correo === item.tecnico && (
                                            <h5 key={index} style={{ fontSize: '12px' }}>{user.nombre} {user.apellido}</h5>
                                        )
                                    )
                                    )}</Table.Cell>
                                    <Table.Cell>{item.fecha_out ? ((item.fecha_out.toDate() - item.date.toDate()) / (1000 * 60 * 60 * 24)).toFixed(3) : ''}</Table.Cell>
                                    {/*  {<Table.Cell
                                    title='Ver Documento Ingreso'
                                    onClick={() => {
                                        leerTestIngreso(item.id)
                                        leerIngresoCab(item.id, 3);
                                        setOpenModalCli(!openModalCli)
                                        setTituloModal('Detalle de Ingreso')
                                        setMostrarSelec(false);
                                    }}
                                ><MdIcons.MdFactCheck style={{ fontSize: '20px', color: '#328AC4' }} />
                                </Table.Cell>} */}
                                </Table.Row>
                            ))
                        ) : (
                            ''
                        )}
                    </Table.Body>
                </Table>
                </Container>
            </Segment>


        </Container>
    );
};

export default ServicioTecnico;